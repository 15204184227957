import React from "react";

const NotFound = () => {
    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="text-center">
            <h1 className="mb-4 fw-bold">404 <br/> Page not found!</h1>
            <a href="/" className="btn-black">Back to Home</a>
          </div>
        </div>
      );
}

export default NotFound;