import React from "react";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import teamWork from "../assets/images/about/team-work.jpg";
import teamWorkWebp from "../assets/images/about/team-work.webp";
import about from "../assets/images/about/about-us.jpg";
import aboutWebp from "../assets/images/about/about-us.webp";
import vision from "../assets/images/about/vision.png";
import visionWebp from "../assets/images/about/vision.webp";
import mission from "../assets/images/about/mission.png";
import missionWebp from "../assets/images/about/mission.webp";

const AboutUs = ()=> {

    const { ref: heroSectionRef, inView: heroSectionInView } = useInView({ triggerOnce: true });

    // Vision section hooks
    const { ref: visionRef, inView: visionInView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const { ref: visionImgRef, inView: visionImgInView } = useInView({ triggerOnce: true, threshold: 0.5 });

    // Mission section hooks
    const { ref: missionRef, inView: missionInView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const { ref: missionImgRef, inView: missionImgInView } = useInView({ triggerOnce: true, threshold: 0.5 });

    // Office Location hook
    // const { ref: rollerRef, inView: rollerInView } = useInView({ triggerOnce: true });


    return(
        <>
            <div className="container-fluid hero-section-about px-4 px-lg-5">
                <div className="row digital-interaction">
                    <div 
                        ref={heroSectionRef}
                        className={`col-lg-12 slide-in-left ${heroSectionInView ? 'visible' : ''}`}
                    >
                        <h1 className="heading mb-4">Transforming Your <span className="blue-orange-gradient-text"> Digital Interaction </span><br/> into a Pivotal Experience</h1>
                        <p className="f-montserrat mb-4">
                            Got a bold idea? We specialize in turning daring ideas into tangible results.
                        </p>
                        <Link to="/contact-us" className="custom-button">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="container-fluid w-80 p-4 p-lg-5">    

                <div className="row mb-5">
                    <div className="col-lg-12">
                        <picture>
                            <source srcSet={teamWorkWebp} type="image/webp" />
                            <img src={teamWork} className="w-100" alt="Team"/>
                        </picture>
                    </div>
                </div>

                <div className="row mb-2 mb-lg-5 text-center text-lg-start">
                    <div className="col-6 col-lg-4 d-flex justify-content-center justify-content-lg-start px-0 px-lg-2">
                        <div>
                            <span className="heading"><CountUp start={0} end={75} duration={6} />+</span>
                            <p>Projects Completed</p>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center px-0 px-lg-2">
                        <div>
                            <span className="heading"><CountUp start={0} end={14} duration={6} />+</span>
                            <p>Countries</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0">
                        <div>
                            <span className="heading"><CountUp start={0} end={82} duration={6} />%</span>
                            <p>Customer Satisfaction</p>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-lg-12">
                        <div
                            ref={rollerRef}
                            className={`roller d-none d-lg-block ${rollerInView ? 'animate' : ''}`} 
                        >
                            <h2 className="heading " id="rolltext">
                                <span>Nawrek</span><br/>
                                <span>London</span><br/>
                                <span>Nawrek, New Jersey</span><br/>
                            </h2>
                        </div>
                        <h2 className="d-block d-lg-none">Nawrek, New Jersey</h2>
                        <p>Head office</p>
                    </div>
                </div> */}

            </div>

            <div className="container-fluid p-4 p-lg-5">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="heading mb-4">About <span className="gradient-text">Us</span></h2>
                        <p className="f-montserrat w-100 w-lg-75 m-auto mb-5">
                            From crafting unique websites to providing extensive custom graphic offerings, our expertise spans design, 
                            development, and digital content strategies. We leverage inventive solutions that amplify you with a distinct brand voice, 
                            enabling you to be prominent in a crowded digital marketplace.
                        </p>

                        <picture>
                            <source srcSet={aboutWebp} type="image/webp" />
                            <img src={about} className="w-100" alt="About"/>
                        </picture>

                    </div>
                </div>
            </div>

            <div className="container-fluid p-4 p-lg-5">
                <div className="row d-flex align-items-stretch">
                    <div
                        ref={visionRef}
                        className={`col-lg-8 d-flex flex-column justify-content-center mb-5 mb-lg-0 fade-in ${visionInView ? 'visible' : ''}`}
                    >
                        <h2 className="heading">Our <span className="gradient-text">Vision</span></h2>
                        <p className="f-montserrat">
                            We champion a causative approach that yields steady, productive results and longstanding solutions. 
                            Blending creativity with contemporary know-how, we navigate you through the complexities of the ever-evolving 
                            digital landscape to achieve transformative growth.
                        </p>
                        <p className="f-montserrat">
                            Enhance your online presence with our premium services. Let us be the catalyst for your digital marketing success.
                        </p>
                    </div>
                    <div className="col-lg-4 vision-bg-img text-center">
                        <picture>
                            <source srcSet={visionWebp} type="image/webp" />
                            <img
                            ref={visionImgRef}
                            src={vision}
                            className={`w-100 vision-img mt-0 mt-lg-5 slide-in-right ${visionImgInView ? 'visible' : ''}`}
                            alt="Vision"
                            />
                        </picture>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-4 p-lg-5">
                <div className="row d-flex align-items-stretch">
                    <div
                        ref={missionRef}
                        className={`col-lg-8 d-flex flex-column justify-content-center mb-5 mb-lg-0 fade-in ${missionInView ? 'visible' : ''}`}
                    >
                        <h2 className="heading">Our <span className="gradient-text">Mission</span></h2>
                        <p className="f-montserrat-bold mb-2">
                            Innovate. Inspire. Impact. 
                        </p>
                        <p className="f-montserrat">
                            We empower brands to transcend the ordinary 
                            and achieve the extraordinary. We strive to push the boundaries of digital innovation with every project we undertake.
                        </p>
                        <p className="f-montserrat">
                            Share your ideas with us. Watch as we bring them to life!
                        </p>
                    </div>
                    <div
                        ref={missionImgRef}
                        className={`col-lg-4 slide-in-top ${missionImgInView ? 'visible' : ''}`}
                    >
                        <picture>
                            <source srcSet={missionWebp} type="image/webp" />
                            <img src={mission} className="w-100" alt="Mission" />
                        </picture>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutUs;