import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Logolight from "../assets/images/header/logo-light.png";
import LogoDark from "../assets/images/header/logo-dark.png";

const Header = ({ theme, toggleTheme }) => {
  
  const isDarkMode = theme === "dark";
  const svgFillColor = theme === "light" ? "#252525" : "#ffffff";
  const [isMenuOpen, setMenuOpen] = useState(false);
  const collapseRef = useRef(null);

  const handleLinkClick = (e) => {
    if (collapseRef.current && isMenuOpen) {
      collapseRef.current.classList.remove("show");
      setMenuOpen(false);
    }

    // Only scroll if the Get In Touch button is clicked
    if (e.target.dataset.scroll === "get-in-touch") {
      setTimeout(() => {
        const contactForm = document.getElementById("contact-form");
        if (contactForm) {
          contactForm.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Adjust the delay if necessary
    }

  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="position-absolute w-100 z-3 p-4 px-lg-5 py-lg-4">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid px-0">
            <NavLink className="navbar-brand" to="/">
              <img
                src={isDarkMode ? Logolight : LogoDark}
                alt="Logo"
                className="logo"
              />
            </NavLink>
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? "show" : ""}`}
              id="mynavbar"
              ref={collapseRef}
            >
              <ul className="navbar-nav header-menu">
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={handleLinkClick}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={handleLinkClick}
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={handleLinkClick}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/portfolio"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(e);
                    }}
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    onClick={handleLinkClick}
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) =>
                      isActive ? "btn header-btn rounded-pill shadow border-0 active" : "btn header-btn rounded-pill shadow border-0"
                    }
                    onClick={handleLinkClick}
                    data-scroll="get-in-touch"  // Unique identifier for scroll trigger
                  >
                    Get In Touch
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid d-flex justify-content-end px-2">
          <div onClick={toggleTheme} style={{ cursor: "pointer" }}>
            {/* Your SVG icon for light/dark mode toggle */}
            <svg
              className="bulb-icon-svg"
              width="40"
              height="40"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="25.5941" cy="25.9346" r="24.5" stroke={svgFillColor} />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5941 10.9346C18.9667 10.9346 13.5941 16.3071 13.5941 22.9346C13.5941 25.6337 14.487 28.1285 15.9929 30.134C16.7352 31.1226 17.5907 31.985 18.3903 32.7704C18.4994 32.8775 18.6072 32.9829 18.7136 33.0872C19.4103 33.7688 20.052 34.3965 20.6325 35.0757L21.153 35.6846H30.0352L30.5557 35.0757C31.1362 34.3967 31.7771 33.7695 32.4739 33.0879C32.5805 32.9835 32.6887 32.8778 32.7979 32.7704C33.5975 31.985 34.453 31.1226 35.1953 30.134C36.7012 28.1285 37.5941 25.6337 37.5941 22.9346C37.5941 16.3071 32.2216 10.9346 25.5941 10.9346ZM16.5941 22.9346C16.5941 17.964 20.6236 13.9346 25.5941 13.9346C30.5647 13.9346 34.5941 17.964 34.5941 22.9346C34.5941 24.9618 33.9256 26.8287 32.7964 28.3326C32.2021 29.124 31.4906 29.8494 30.6959 30.63C30.5941 30.7299 30.4904 30.8312 30.3856 30.9336C29.8265 31.4804 29.2303 32.0631 28.6654 32.6846H22.5229C21.9579 32.0631 21.3617 31.4804 20.8026 30.9336C20.698 30.8315 20.5939 30.7296 20.4924 30.63C19.6976 29.8494 18.9862 29.124 18.3919 28.3326C17.2626 26.8287 16.5941 24.9618 16.5941 22.9346Z"
                fill={svgFillColor}
              />
              <path
                d="M30.0941 40.9346V37.9346H21.0941V40.9346H30.0941Z"
                fill={svgFillColor}
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
