import React from "react";
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import cube from "../assets/images/home/cube.png";
import cubeWebp from "../assets/images/home/cube.webp";
import gd from "../assets/images/home/gd.png";
import gdWebp from "../assets/images/home/gd.webp";
import wn from "../assets/images/home/wn.png";
import wnWebp from "../assets/images/home/wn.webp";
import asq from "../assets/images/home/asq.png";
import asqWebp from "../assets/images/home/asq.webp";
import gt from "../assets/images/home/gt.png";
import gtWebp from "../assets/images/home/gt.webp";
import will from "../assets/images/home/will.png";
import willWebp from "../assets/images/home/will.webp";


const Home = ()=> {

  const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true });
  const { ref: cardRef, inView: cardInView } = useInView({ triggerOnce: true });
  const { ref: contentRef, inView: contentInView } = useInView({ triggerOnce: true });

  return(
    <>
      <div className="container-fluid hero-section-home px-4 px-lg-5">

        {/* <div className="row my-4 d-none d-xxl-block">
          <div className="col-lg-12 py-5"></div>
        </div> */}

        <div className="row my-5 d-none d-xxl-block">
          <div className="col-lg-12 py-4"></div>
        </div>
        <div className="row my-5">
          <div className="col-lg-12 py-5"></div>
        </div>

        <div className="row">
        <div className="col-lg-12">
          <h1
            ref={headingRef}
            className={`text-center heading pb-5 mb-5 fade-in ${headingInView ? 'visible' : ''}`}
          >
            We Design, Develop & <span className="gradient-text">Market Digital Solutions</span>
          </h1>
        </div>
      </div>

      <div className="row mb-5 mb-lg-0">
        <div className="col-lg-3 mb-4 pe-xxl-5">

          <div className="card-container d-flex flex-row gap-4">
            <div
            ref={cardRef}
            className={`card custom-card  px-4 py-4 py-lg-5 slide-in-left ${cardInView ? 'visible' : ''}`}
            >
              <p className="mb-0">75+ <br />Successful Projects</p>
            </div>
            <div
              className={`card custom-card px-4 py-4 py-lg-5 slide-in-left ${cardInView ? 'visible' : ''}`}
            >
              <p className="mb-0">92% <br /> Faster Delivery</p>
            </div>
          </div>

        </div>
        <div className="col-lg-6"> </div>
        <div className="col-lg-3 ps-lg-5">
          <p
            ref={contentRef}
            className={`f-montserrat mb-4 slide-in-right ${contentInView ? 'visible' : ''}`}
          >
            Welcome to Pivot Tech, your one-stop shop for digital services. We turn your daring ideas into reality, 
            elevating your brand through digital innovation and paving your road to virtual success.
          </p>
          <Link to="/services" className="custom-button">Learn More</Link>
        </div>
      </div>

      </div>

      <section className="our-services">

        <div className="container-fluid p-4 p-lg-5">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-lg-center heading mb-4 mb-lg-5">Our <span className="gradient-text">Services</span></h2>
            </div>
          </div>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-5 d-flex flex-column align-items-center justify-content-center mb-5 mb-lg-0">
              <p className="f-montserrat">
                Success online entails a strategic approach. At Pivot Tech, we pride ourselves on being your versatile partner for all your online needs.
              </p>
              <p className="f-montserrat mb-0">
                Whether you are looking for website design and development services, web maintenance support, social media marketing, creative e-commerce design assistance, 
                or Shopify theme customizations, we provide personalized solutions that align with your ambitions. Our extensive skills ensure that every aspect of your digital 
                presence is cohesive, specialized, and effective.
              </p>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                      Web Design & Development
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                    <div className="accordion-body d-flex flex-column">
                      <span className="mb-4">
                      Our web designs are crafted by thoroughly understanding your objectives, audience demographics, and conversion goals. 
                      Courtesy a performance-driven approach, we create unique websites that not only look impressive but also function seamlessly, 
                      wherein every element connects to generate impactful results.
                      </span>
                      <Link to="/services#website-design-development" className="custom-button" style={{width:"fit-content"}}>Learn More</Link>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      Web Maintenance Services
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body d-flex flex-column">
                      <span className="mb-4">
                        Pivot Tech offers in-depth website maintenance services and support for HTML, CSS, JavaScript, 
                        as well as different platforms such as WordPress, Webflow, and Wix.
                        <br/><br/>
                        Through our continuous attention and care to your website, your online presence stands to stay ahead of the curve.
                      </span>
                      <Link to="/services#website-maintenance" className="custom-button" style={{width:"fit-content"}}>Learn More</Link>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Content Strategy Services
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div className="accordion-body d-flex flex-column">
                      <span className="mb-4">
                        A brand transcends a mere logo and slogan. It necessitates an effective content marketing strategy too, one that embodies your core values,
                        mission, and vision. Our client-specific approaches are designed to boost responsiveness and cultivate long-lasting brand recognition. 
                        We define your central message, target market, and distinct voice, whereby your digital presence reflects your brand’s DNA, through effective storytelling, 
                        social media marketing (SMM), visual content, and buyer persona development.<br/><br/>
                        Care to also get featured in top-tier online publications? Our contacts extend far and wide.<br/><br/>
                        Expand your visibility with an expertly crafted article, tailored specifically for Business Insider, New York Weekly (NY Weekly), USA TODAY, and AP News. 
                        These reputable and widely-read media outlets serve the perfect platforms for you to capture attention online, increase your audience’s engagement, 
                        amplify your credibility, and enhance your brand notability.
                      </span>
                      <Link to="/services#strategic-marketing" className="custom-button" style={{width:"fit-content"}}>Learn More</Link>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                      Creative Design Services
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                    <div className="accordion-body d-flex flex-column">
                      <span className="mb-4">
                        Transform your digital presence and captivate your audience with Pivot Tech’s artistic design offerings. We specialize in:<br/>
                        <ul className="list-group custom-list">
                          <li className="list-group-item bg-transparent border-0">Logos</li>
                          <li className="list-group-item bg-transparent border-0">Digital illustrations</li>
                          <li className="list-group-item bg-transparent border-0">Online ad designs</li>
                          <li className="list-group-item bg-transparent border-0">High-resolution product visualizations</li>
                          <li className="list-group-item bg-transparent border-0">Customized book covers</li>
                          <li className="list-group-item bg-transparent border-0">Visual retouching services</li>
                          <li className="list-group-item bg-transparent border-0">Engaging and informative infographics</li>
                        </ul>
                        By combining creativity with strategic thinking, we enhance your online identity, propel engagement, and boost conversions. Discover how our expert solutions will elevate your digital profile.<br/><br/>
                        Connect today to bring your ideas to life.
                      </span>
                      <Link to="/services#branding-strategies" className="custom-button" style={{width:"fit-content"}}>Learn More</Link>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 bg-transparent">
                  <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                      E-Commerce & Shopify 
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                    <div className="accordion-body d-flex flex-column">
                      <span className="mb-4">
                        Imagine a physical store that suits your tastes and where everything, from the layout to product display, 
                        is carefully arranged to provide an exceptional shopping experience. That’s exactly the feeling your Shopify store should evoke in visitors.<br/><br/>
                        In today’s digital age, a Shopify store isn’t just a platform to sell products. It’s a canvas to tell your brand story, 
                        showcase your unique selling points, and, most importantly, convert visitors into paying customers.<br/><br/>
                        With over 1.7 million businesses relying on Shopify to sell their products online, the question is: how can you stand out?
                      </span>
                      <Link to="/services#shopify-website-design" className="custom-button" style={{width:"fit-content"}}>Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-4 p-lg-5">
          <div className="row">
            <div className="col-lg-12 py-1"></div>
          </div>
        </div>

        <div className="container-fluid w-lg-90 p-4 p-lg-5">
          <div className="row">
            <div className="col-lg-5 mb-5 mb-lg-0">
              <h2 className="heading mb-4">Who <span className="gradient-text">We Are</span></h2>
              <p className="f-montserrat mb-4">
                Pivot Tech is your comprehensive, strategic ally for all your digital requirements. 
                We are dedicated to helping your brand flourish online, courtesy our innovative activities 
                designed to expand your virtual presence, enhance user engagement, and drive meaningful conversions.
              </p>
              <Link to="/about-us" className="custom-button">Read More</Link>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5 text-end">
              <picture>
                <source srcSet={cubeWebp} type="image/webp" />
                <img src={cube} alt="Cube" className="w-100 cube-img" />
              </picture>
            </div>
          </div>
        </div>

      </section>

      <div className="container-fluid section-experiences p-4 p-lg-5">

        <div className="row pb-5">
          <div className="col-lg-12 text-center">
            <h2 className="heading mb-5">Our <span className="gradient-text">Experiences</span> </h2>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="d-flex justify-content-around align-items-center flex-wrap horizontal-scroll">
              <div className="p-2">
                  <picture>
                    <source srcSet={gdWebp} type="image/webp" />
                    <img src={gd} alt="GD" className="img-fluid our-experiences-item"/>
                  </picture>
              </div>
              <div className="p-2">
                  <picture>
                    <source srcSet={wnWebp} type="image/webp" />
                    <img src={wn} alt="WN" className="img-fluid our-experiences-item"/>
                  </picture>
              </div>
              <div className="p-2">
                  <picture>
                    <source srcSet={asqWebp} type="image/webp" />
                    <img src={asq} alt="ASQ" className="img-fluid our-experiences-item"/>
                  </picture>
              </div>
              <div className="p-2 fourth-item">
                  <picture>
                    <source srcSet={gtWebp} type="image/webp" />
                    <img src={gt} alt="GT" className="img-fluid our-experiences-item"/>
                  </picture>
              </div>
              <div className="p-2">
                  <picture>
                    <source srcSet={willWebp} type="image/webp" />
                    <img src={will} alt="Will" className="img-fluid our-experiences-item"/>
                  </picture>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>

        <div className="row">
          <div className="col-lg-12 py-4"></div>
        </div>

        <div className="row py-5">
          <div className="col-lg-12 text-center">
            <h3 className="heading-secondary mb-5">Hit the button. Discuss your ideas with us!</h3>
            <Link to="/contact-us#contact-form" className="custom-button say-hello-btn">Let’s Connect</Link>
          </div>
        </div>
        
      </div>

    </>
  );
}

export default Home;