import React from "react";
import { Link } from "react-router-dom";
import Logolight from "../assets/images/footer/logo-light.png";
import LogoDark from "../assets/images/footer/logo-dark.png";


const Footer = ({ theme, toggleTheme })=> {
    
    const isDarkMode = theme === 'dark';

    return(
        <>
        <div className="container-fluid footer-container p-4 px-lg-5 pt-lg-4 pb-lg-3" style={{ backgroundColor: isDarkMode ? '#000000' : '#F8F8F8' }}>
            <div className="row">
                <div className="col-lg-8">
                    <img src={isDarkMode ? Logolight : LogoDark} alt="Logo" className="w-25 mb-4"/>
                    <p className="fs-5 locatio-info mb-0"><a className="text-decoration-none" href="https://maps.app.goo.gl/grAhYYtUySsdS8536" target="_blank" rel="noopener noreferrer">8 The Green #7256, Dover, DE 19901</a></p>
                    <p className="fs-5 contact-info">
                        <a href="mailto:info@thepivotech.com" className="text-decoration-none">info@thepivotech.com</a>
                        <span className="separator"> | </span>
                        <a href="tel:+13022008852" className="text-decoration-none">1 (302) 200-8852</a>
                    </p>
                </div>
                <div className="col-lg-2">
                    <ul className="nav flex-column mb-0 mb-lg-4 footer-nav d-none d-lg-block">
                        <li className="nav-item">
                            <Link className="nav-link px-0" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0" to="/about-us">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0" to="/services">Services</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-2">
                    <ul className="nav flex-column mb-4 footer-nav d-none d-lg-block">
                        <li className="nav-item">
                            <Link className="nav-link px-0" to="#">Portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link px-0" to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <p className="fs-5">Stay in Touch with Us!</p>
                    <form className="d-flex flex-column mb-4 mb-lg-0">
                        <input className="form-control rounded-pill me-2 mb-4 py-2 px-3 py-lg-3 px-lg-4 border-0 shadow footer-input-field" type="text" placeholder="Enter your email"/>
                        <button className=" rounded-pill footer-submit-btn shadow border-0" type="button">Submit</button>
                    </form>
                </div>
                <div className="col-lg-4 d-flex flex-column justify-content-between">

                    <div className="d-flex align-items-center gap-4 my-4 mb-lg-0">
                        <a href="https://www.instagram.com/pivot.tech/?igsh=MXFuZ21xbHc1djBqYg%3D%3D" target="_blank" rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="21px" height="21px">
                                <path 
                                    d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" 
                                    fill={isDarkMode ? "#ffffff" : "#252525"}
                                    stroke={isDarkMode ? "#ffffff" : "#252525"}
                                    strokeWidth="2px"
                                />
                            </svg>
                        </a>

                        <a href="https://www.facebook.com/profile.php?id=61564660696173" target="_blank" rel="noopener noreferrer">
                            <svg width="13" height="21" viewBox="0 0 13 21" fill="none">
                                <path d="M9.66797 0.0419922H12.668V4.04199H10.668C9.56337 4.04199 8.66797 4.93742 8.66797 6.04199V8.04199H12.668L11.6245 12.042H8.49407V20.042H4.32014V12.042H0.667969V8.08159H4.66797V5.04199C4.66797 2.28057 6.90657 0.0419922 9.66797 0.0419922Z" fill={isDarkMode ? "#ffffff" : "#252525"} />
                            </svg>
                        </a>

                        <a href="https://www.linkedin.com/company/thepivotech/ " target="_blank" rel="noopener noreferrer">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M2.50891 4.04199C3.61348 4.04199 4.50891 3.14656 4.50891 2.04199C4.50891 0.937423 3.61348 0.0419922 2.50891 0.0419922C1.40434 0.0419922 0.508911 0.937423 0.508911 2.04199C0.508911 3.14656 1.40434 4.04199 2.50891 4.04199Z" fill={isDarkMode ? "#ffffff" : "#252525"} />
                                <path d="M4.50891 7.04199H0.508911V20.042H4.50891V7.04199Z" fill={isDarkMode ? "#ffffff" : "#252525"} />
                                <path d="M8.50891 20.042H12.5089V13.042C12.5089 11.9374 13.4043 11.042 14.5089 11.042C15.6135 11.042 16.5089 11.9374 16.5089 13.042V20.042H20.5089V13.042C20.5089 9.72829 17.8226 7.04199 14.5089 7.04199C11.1952 7.04199 8.50891 9.72829 8.50891 13.042V20.042Z" fill={isDarkMode ? "#ffffff" : "#252525"} />
                            </svg>
                        </a>

                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <svg width="25" height="16" viewBox="0 0 25 16" fill="none">
                                <path d="M22.424 2.54199H15.424V0.541992H22.424V2.54199ZM24.15 12.542C23.708 13.839 22.121 15.542 19.049 15.542C15.975 15.542 13.485 13.813 13.485 9.86699C13.485 5.95699 15.81 3.94699 18.951 3.94699C22.033 3.94699 23.915 5.72899 24.326 8.37299C24.4039 8.87899 24.4349 9.56099 24.4209 10.513H16.394C16.524 13.724 19.877 13.825 20.982 12.542H24.15ZM16.464 8.54199H21.4289C21.3239 6.99499 20.2929 6.32299 18.952 6.32299C17.486 6.32299 16.675 7.09099 16.464 8.54199ZM6.88995 15.53H0.42395V0.562992H7.37695C12.853 0.643992 12.957 6.00699 10.097 7.46899C13.558 8.72899 13.674 15.53 6.88995 15.53ZM3.42395 6.54199H7.00795C9.51595 6.54199 9.91395 3.54199 6.69595 3.54199H3.42395V6.54199ZM6.81495 9.54199H3.42395V12.558H6.76495C9.81995 12.558 9.63295 9.54199 6.81495 9.54199Z" fill={isDarkMode ? "#ffffff" : "#252525"} />
                            </svg>
                        </a>
                    </div>

                    <p className="fs-4 text-grey">©{new Date().getFullYear()} Pivot Technologies LLC</p>

                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;