import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import phoneIcon from "../assets/images/contact/phone.png";
import emailIcon from "../assets/images/contact/mail.png";
import locationIcon from "../assets/images/contact/location.png";

const ContactUs = ()=> {

    const [formData, setFormData] = useState({
        fullName: '',
        emailAddress: '',
        phone: '',
        message: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const location = useLocation();

    useEffect(() => {
      if (location.hash === '#contact-form') {
        const element = document.getElementById('contact-form');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData( (prevData) => ({ ...prevData, [id]: value,}) );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 
    
        try {
            const response = await axios.post('http://thepivotech.com/contact.php', formData, {
                headers: {
                    'Content-Type': 'application/json', 
                },
            });
    
            if (response.data.status === true) {
                setResponseMessage('Your message was sent successfully!');
                setFormData({ fullName: '', emailAddress: '', phone: '', message: '' }); // Reset form fields
            } else {
                setResponseMessage('There was an error sending your message.');
            }
        } catch (error) {
            setResponseMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false); 
        }
    };

    useEffect(() => {
        if (responseMessage) {
          const timer = setTimeout(() => {
            setResponseMessage('');
          }, 5000); // Hide after 5 seconds
    
          // Clear the timer when component unmounts or message changes
          return () => clearTimeout(timer);
        }
    }, [responseMessage]);

    return(
        <>
            <div className="container-fluid hero-section-contact px-4 px-lg-5">
                <div className="row vision-life">
                    <div  className="col-lg-12">
                        <h1 className="heading mb-4"><span className="radish-gradient-text">Ready</span> to Bring <br/> Your Vision to Life?</h1>
                        <a href="#contact-form" className="custom-button">Learn More</a>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-4 p-lg-5">
                <div className="row p-3 p-lg-5" id="contact-form">
                    <div className="col-lg-12 text-center">
                        <h2 className="heading-secondary mb-4 mb-lg-2">Connect today to pivot your brand to new heights!</h2>
                    </div>
                </div>
                <div className="row rounded-5 px-2 py-4 px-lg-4 py-lg-4" style={{backgroundColor: "#7A48D2"}}>
                    <div className="col-md-6">
                        <div className="card contact-card rounded-5 text-light h-100">
                            <h2 className="heading-secondary mb-2">Contact Information</h2>
                            <p className="pb-5">Let’s turn your ideas into action!</p>
                            <ul className="list-unstyled py-3 py-lg-5">
                                <li className="d-flex align-items-center mb-2"><img src={phoneIcon} className="d-inline-block me-3" alt="Phone" style={{width:"15px"}}/><span className="d-inline-block"><a href="tel:+13022008852" className="text-light text-decoration-none fs-4">1 (302) 200-8852</a> <br/> </span> </li>
                                <li className="d-flex align-items-center my-3 my-lg-5"><img src={emailIcon} className="d-inline-block me-3" alt="Phone" style={{width:"15px"}}/><a href="mailto:info@thepivotech.com" className="d-inline-block text-light text-decoration-none fs-4">info@thepivotech.com</a></li>
                                <li className="d-flex align-items-center mb-2"><img src={locationIcon} className="d-inline-block me-3" alt="Phone" style={{height:"20px"}}/><a className="d-inline-block text-light text-decoration-none fs-4" href="https://maps.app.goo.gl/grAhYYtUySsdS8536" target="_blank" rel="noreferrer"> 8 The Green #7256, Dover, DE 19901 </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card bg-transparent border-0 px-0 px-lg-4 py-4">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label htmlFor="fullName" className="form-label text-white mb-2 mb-xxl-4">Full Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control conatct-form-input" 
                                        id="fullName" 
                                        placeholder="John Doe" 
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="emailAddress" className="form-label text-white mb-2 mb-xxl-4">Email Address*</label>
                                    <input 
                                        type="email" 
                                        className="form-control conatct-form-input" 
                                        id="emailAddress" 
                                        placeholder="JohnDoe@email.com" 
                                        value={formData.emailAddress}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="phone" className="form-label text-white mb-2 mb-xxl-4">Phone No. (optional)</label>
                                    <input 
                                        type="text" 
                                        className="form-control conatct-form-input" 
                                        id="phone" 
                                        placeholder="Phone No." 
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="message" className="form-label text-white mb-2 mb-xxl-4">Enter Your Message here</label>
                                    <textarea 
                                        className="form-control conatct-form-input" 
                                        id="message" 
                                        rows="3" 
                                        placeholder="Your message here" 
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        required
                                    ></textarea>
                                </div>

                                <button 
                                    type="submit" 
                                    className="btn conatct-form-btn rounded-pill text-light shadow border-0" 
                                    disabled={loading}
                                >
                                    {loading ? 'Sending...' : 'Submit'}
                                </button>
                                {responseMessage && <p className="mt-4 text-white">{responseMessage}</p>}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs;