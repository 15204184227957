import React, { useState, useEffect } from "react";
import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import Header from "./shared/Header";
import Footer from "./shared/Footer";


function App() {
  
  const [theme, setTheme] = useState('dark');

  // Toggle theme between light and dark
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  // Apply theme to body element
  useEffect(() => {
    document.body.className = theme === 'light' ? 'light-mode' : 'dark-mode';
  }, [theme]);


  return (
    <>
      <Router>  
      <ScrollToTop/>     
        <div className={`app ${theme}-mode overflow-x-hidden`}>
          <Header theme={theme} toggleTheme={toggleTheme} />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
          <Footer theme={theme} toggleTheme={toggleTheme}/>
        </div>
      </Router>
    </>
  );
}

export default App;
