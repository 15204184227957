import React, { useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import { Link, useLocation } from "react-router-dom";
import strategies from "../assets/images/services/strategies.jpg";
import strategiesWebp from "../assets/images/services/strategies.webp";
import strategicMarketing from "../assets/images/services/strategic-marketing.jpg";
import strategicMarketingWebp from "../assets/images/services/strategic-marketing.webp";
import websiteMaintenance from "../assets/images/services/website-maintenance.jpg";
import websiteMaintenanceWebp from "../assets/images/services/website-maintenance.webp";
import websiteRedesign from "../assets/images/services/website-redesign.jpg";
import websiteRedesignWebp from "../assets/images/services/website-redesign.webp";
import shopifyWebsiteDesign from "../assets/images/services/shopify-website-design.jpg";
import shopifyWebsiteDesignWebp from "../assets/images/services/shopify-website-design.webp";

const Services = ()=> {

    const { ref: heroSectionRef, inView: heroSectionInView } = useInView({ triggerOnce: true });

    const location = useLocation();

    useEffect(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1)); // Removes the '#' from the hash
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);

    return(
        <>
            <div className="container-fluid hero-section-services px-4 px-lg-5">
                <div className="row empowering-businesses">
                    <div
                        ref={heroSectionRef}
                        className={`col-lg-12 slide-in-left ${heroSectionInView ? 'visible' : ''}`}
                    >
                        <h1 className="heading mb-4"><span className="radish-gradient-text">Empowering</span> Businesses <br /> with Innovative Digital Solutions</h1>
                        <Link to="/contact-us" className="custom-button">Learn More</Link>
                    </div>
                </div>
            </div>

            <section className="maintenance-redesign">

            <div className="container-fluid section-website-redesign p-4 p-lg-5" id="website-design-development">
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="heading mb-4">Web Design and<span className="purple-text"> Development </span></h2>
                        <p className="f-montserrat mb-4">
                            Our personalized website design solutions are constructed by first assiduously comprehending
                            your aims, focus market, and conversion targets. We then develop exclusive web designs that not 
                            only stand out but also operate efficiently, making sure each component unifies to substantial effect.
                        </p>
                        <p className="f-montserrat mb-4">
                            We additionally specialize in improving website UI/UX designs, to afford an immersive and 
                            interactive user experience across all devices. Our mobile-friendly and SEO-friendly website (re)designs, 
                            characterized by a performance-based approach and a focus on conversion optimization, guarantee that every 
                            aspect of your site is positioned for success.
                        </p>
                        <p className="f-montserrat mb-4">
                            From individualized web designs to unique website redesigns, our custom feature services utilize the 
                            latest tools and trends to create a responsive, visually appealing, and highly functional site that 
                            will enrich every aspect of you online.
                        </p>
                        <p className="f-montserrat mb-5">
                            Ready to enhance your user experience? Make the most of an engaging website and innovative features, today!
                        </p>
                        <Link to="/contact-us#contact-form" className="custom-button mb-5 mb-lg-0">Get a Quote</Link>
                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-4">
                        <picture>
                            <source srcSet={websiteRedesignWebp} type="image/webp" />
                            <img src={websiteRedesign} className="w-100 mt-lg-5" alt="Website Redesign"/>
                        </picture>
                    </div>
                </div>
            </div>


            <div className="container-fluid section-website-maintenance p-4 p-lg-5" id="website-maintenance">
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="heading mb-4">Website <span className="purple-text"> Maintenance </span></h2>
                        <p className="f-montserrat mb-4">
                            A website is a dynamic asset that demands constant care and updates to remain relevant and effective.
                        </p>
                        <p className="f-montserrat mb-4">
                            At Pivot Tech, we provide comprehensive website maintenance services, proficiently handling HTML, CSS, and JavaScript, alongside various platforms such as Webflow and WordPress. We endeavor your virtual presence remains progressive and viable.
                        </p>
                        <p className="f-montserrat mb-5">
                            Our services encompass:
                        </p>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Proactive Updates</h3>
                            </div>
                            <p className="f-montserrat">Regular updates to enhance performance, security, and user experience.</p>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Performance Optimization</h3>
                            </div>
                            <p className="f-montserrat">Quick load times and seamless functionality across all devices.</p>
                        </div>

                        <div className="mb-5">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Feature Integration</h3>
                            </div>
                            <p className="f-montserrat">
                                Adding new functionalities, and refining existing ones, to keep your website competitive.
                            </p>
                        </div>

                        <p className="f-montserrat mb-5">
                            Our maintenance services allow you to focus on your core business while we handle your website’s 
                            smooth and efficient operation.
                        </p>

                        <Link to="/contact-us#contact-form" className="custom-button mb-5 mb-lg-0">Get a Quote</Link>

                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-4">
                        <picture>
                            <source srcSet={websiteMaintenanceWebp} type="image/webp" />
                            <img src={websiteMaintenance} className="w-100 mt-lg-5" alt="Website Maintenance"/>
                        </picture>
                    </div>
                </div>
            </div>

            </section>

            <section className="strategies-marketing">

            <div className="container-fluid section-branding-strategies p-4 p-lg-5" id="branding-strategies">
                <div className="row py-5 d-none d-lg-block">
                    <div className="col-lg-12"></div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="heading mb-4">Branding<span className="purple-text"> Strategies </span></h2>
                        <p className="f-montserrat mb-4">
                            Branding goes beyond an emblem and tagline. It should also personify your fundamental principles, objectives, and aspirations.
                        </p>
                        <p className="f-montserrat mb-4">
                            Our client-centered methods improve engagement and foster brand awareness. We characterize your key message, intended market, 
                            and unique voice, so that your digital presence echoes your brand’s identity.
                        </p>
                        <p className="f-montserrat mb-5">
                            Our holistic and integrated practices focus on:
                        </p>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">In-depth Brand Analysis</h3>
                            </div>
                            <p className="f-montserrat">Assessing your brand’s essence to understand what makes it unique.</p>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Target Audience Research</h3>
                            </div>
                            <p className="f-montserrat">     
                                Identifying and understanding the needs, behaviors, and preferences of your customer base, 
                                to personalize messaging and design.
                            </p>
                        </div>

                        <div className="mb-5">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Consistent Brand Voice</h3>
                            </div>
                            <p className="f-montserrat">
                                Utilizing detailed information to fashion and execute a coherent 
                                brand personality across different platforms.
                            </p>
                        </div>

                        <p className="f-montserrat mb-5">
                            Our broad, goal-oriented design plan steers optimal results and long-term success 
                            in publicizing an integrated brand narrative across diverse channels. With Pivot Tech, 
                            your promotional campaign will be seen and remembered.
                        </p>

                        <Link to="/contact-us#contact-form" className="custom-button mb-5 mb-lg-0">Get a Quote</Link>

                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-4">
                        <picture>
                            <source srcSet={strategiesWebp} type="image/webp" />
                            <img src={strategies} className="w-100 mt-lg-5" alt="Strategies"/>
                        </picture>
                    </div>
                </div>
            </div>

            <div className="container-fluid section-strategic-marketing p-4 p-lg-5" id="strategic-marketing">
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="heading mb-4">Strategic <span className="purple-text"> Marketing </span></h2>
                        <p className="f-montserrat mb-4">
                            Our tailored strategies are designed to boost engagement and create lasting recognition, 
                            through social media marketing (SMM), valuable storytelling, multimedia content, 
                            and buyer persona development.
                        </p>

                        <p className="f-montserrat mb-5">
                            Our process includes:
                        </p>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Objective Setting</h3>
                            </div>
                            <p className="f-montserrat">Collaborating to establish clear, achievable goals</p>
                        </div>

                        <div className="mb-4">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Consumer Analysis</h3>
                            </div>
                            <p className="f-montserrat">Understanding the needs, behaviors, and preferences of your audience.</p>
                        </div>

                        <div className="mb-5">
                            <div className="d-flex align-items-center">
                                <div className="custom-line"></div> 
                                <h3 className="f-montserrat-bold mb-1">Insight-based Strategies</h3>
                            </div>
                            <p className="f-montserrat">
                                Using information to develop, execute, and sharpen marketing strategies for optimal results.
                            </p>
                        </div>

                        <p className="f-montserrat mb-5">
                            Our content marketing campaign additionally extends to compiling and publishing a professionally 
                            written article on Business Insider, New York Weekly (NY Weekly), USA TODAY, and AP News. 
                            These well-recognized digital news outlets stand to resonate with your ideal audience 
                            while your message hits home, along with improving your search engine rankings, 
                            raising brand recognition, and enriching your prominence.
                        </p>

                        <Link to="/contact-us#contact-form" className="custom-button mb-5 mb-lg-0">Get a Quote</Link>

                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-4">
                        <picture>
                            <source srcSet={strategicMarketingWebp} type="image/webp" />
                            <img src={strategicMarketing} className="w-100 mt-lg-5" alt="Strategic Marketing"/>
                        </picture>
                    </div>
                </div>
            </div>

            </section>


            <div className="container-fluid section-shopify-website-design p-4 p-lg-5" id="shopify-website-design">
                <div className="row py-4 d-none d-lg-block">
                    <div className="col-lg-12"></div>
                </div>
                <div className="row">
                    <div className="col-lg-7">
                        <h2 className="heading mb-4">Shopify Website <span className="purple-text"> Design </span></h2>
                        <p className="f-montserrat mb-4">
                            As e-commerce dominates the virtual landscape, carving out your own space with a high-converting Shopify 
                            storefront serves as a critical asset for your digital presence. Integrating Shopify into your existing 
                            website opens doors to greater opportunities, by leveraging a robust ecommerce ecosystem and converting 
                            visitors into loyal customers.
                        </p>
                        <p className="f-montserrat mb-4">
                            At Pivot Tech, we create responsive, SEO-friendly Shopify stores that enhance your brand’s personality 
                            and provide a seamless user experience. From bespoke Shopify website designs and Shopify theme customizations, 
                            which align with your brand’s aesthetics, to implementing e-commerce best practices, our collaborative strategy 
                            will increase your digital footprint, in relation to improving customer engagement and user interaction. 
                            These Shopify development services, in turn, benefit your online visibility by commanding attention and driving traction, 
                            distinguishing you from your competitors.
                        </p>
                        <p className="f-montserrat mb-5">
                            Remember, in a vast sea of online stores, the ones that portray a compelling story through their design essentially 
                            win hearts and secure sales. Let’s build a Shopify experience that sets you apart and maneuvers your business forward.
                        </p>
                        <Link to="/contact-us#contact-form" className="custom-button mb-5 mb-lg-0">Get a Quote</Link>
                    </div>

                    <div className="col-lg-1"></div>

                    <div className="col-lg-4">
                        <picture>
                            <source srcSet={shopifyWebsiteDesignWebp} type="image/webp" />
                            <img src={shopifyWebsiteDesign} className="w-100 mt-lg-5" alt="Shopify Website Design"/>
                        </picture>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-4 p-lg-5">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h3 className="heading-secondary mb-5">Ready to bring your vision to life or refine <br/> your ideas with our expert consultancy?</h3>
                        <Link to="/contact-us#contact-form" className="custom-button get-quote-btn">Get a Quote</Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Services;